import React, { useEffect, useRef, useState } from "react";
import styles from "./LandingMain.module.css";
import ArrowChevronDownDuo from "@assets/images/home/arrowChevronDownDuo.svg";
import MainImage from "@assets/images/home/mainImage.png";

import logo1 from "@assets/images/home/logos/music_copylight.png";
import logo2 from "@assets/images/home/logos/music_player.jpg";
import logo3 from "@assets/images/home/logos/adobe.png";
import logo4 from "@assets/images/home/logos/vimeo.png";

import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import MainSection from "./MainSection";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { TemplateObj } from "@interfaces/admin";
import { useApi } from "@context/ApiContext";
import ServiceTemplateCard from "@components/Service/Templates/ServiceTemplateCard";
import AdminLoading from "@pages/Admin/AdminLoading";

interface LandingMainProps {
  projectRef: React.RefObject<HTMLDivElement>;
  contactRef: React.RefObject<HTMLDivElement>;
}

const LandingMain: React.FC<LandingMainProps> = ({
  projectRef,
  contactRef,
}) => {
  const { templateApi, adminApi } = useApi();
  const sloganRef = useRef<HTMLDivElement>(null);

  const navigator = useNavigate();

  const [isMobile, setIsMobile] = useState(false);

  const [contact, setContact] = useState({
    contact_name: "",
    contact_email: "",
    contact_content: "",
  });

  // 문의 로딩
  const [contactLoading, setContactLoading] = useState(false);

  const {
    isLoading,
    isError,
    data: templates,
  } = useQuery<TemplateObj[], Error>(
    ["templates"],
    () => templateApi.getTemplates(),
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    // Check screen size on initial load
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // const sections = [
    //   sloganRef.current,
    //   projectRef?.current,
    //   contactRef.current,
    // ];
    // const observer = new IntersectionObserver(
    //   (entries) => {
    //     entries.forEach((entry) => {
    //       if (entry.isIntersecting) {
    //         gsap.to(entry.target, {
    //           opacity: 1,
    //           duration: 2,
    //           ease: "power2.inOut",
    //         });
    //         observer.unobserve(entry.target);
    //       }
    //     });
    //   },
    //   { threshold: 0.1 }
    // );
    // sections.forEach((section) => {
    //   if (section) {
    //     gsap.set(section, { opacity: 0 });
    //     observer.observe(section);
    //   }
    // });
    // return () => {
    //   sections.forEach((section) => {
    //     if (section) {
    //       observer.unobserve(section);
    //     }
    //   });
    // };
  }, []);

  gsap.registerPlugin(ScrollToPlugin);

  const handleScrollToProject = () => {
    if (projectRef.current) {
      window.scrollTo({
        top: projectRef.current?.offsetTop,
        behavior: "smooth",
      });
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setContact({ ...contact, [name]: value });
  };

  const validateFields = () => {
    if (!contact.contact_name.trim()) {
      alert("이름을 입력해주세요.");
      return false;
    }

    if (!contact.contact_email.trim()) {
      alert("이메일을 입력해주세요.");
      return false;
    } else if (!/\S+@\S+\.\S+/.test(contact.contact_email)) {
      alert("유효한 이메일 주소를 입력해주세요.");
      return false;
    }

    if (!contact.contact_content.trim()) {
      alert("문의 내용을 입력해주세요.");
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    try {
      setContactLoading(true);
      if (validateFields()) {
        const params = {
          contact,
          state: "create",
        };

        const res = await adminApi.saveOrUpdateContact(params);

        if (!res) return;

        alert("문의가 등록되었습니다.");
        setContact({
          contact_name: "",
          contact_email: "",
          contact_content: "",
        });
      }
      setContactLoading(false);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div className={styles.mainPage}>
      {contactLoading && <AdminLoading />}
      <div className={styles.contents}>
        <div className={styles.mainImage}>
          <img src={MainImage} alt="main" />
        </div>
        <div
          className={styles.scrollDownWrapper}
          onClick={handleScrollToProject}
        >
          <span className={styles.scrollDown}>Scroll down</span>
          <img
            className={styles.arrowChevronDown}
            src={ArrowChevronDownDuo}
            alt="화살표 아이콘"
          />
        </div>
      </div>

      <main>
        <div className={styles.slogan} ref={sloganRef}>
          <p>
            당신과의 기억을 간직하기 위해 <br />
            하고픈 말과 사진을 담아봅니다
          </p>
        </div>

        <div ref={projectRef} className={styles.projectWrap}>
          <MainSection title="추모영상" className={styles.projects}>
            {templates?.map((template) => (
              <ServiceTemplateCard template={template} key={template.id} />
            ))}
          </MainSection>
        </div>

        <div className={styles.contactWrap}>
          <div className={styles.contactInfoBoxs}>
            <div className={styles.contactInfoBox}>
              <div className={styles.infoWrap}>
                <h6>Cnemo Story</h6>
                <p>
                  시네모는 Cinema Moment의 줄임말로서 <br /> 영화 같은 순간을
                  위해 설립되었습니다. <br />
                  다큐멘터리스트, 디자이너, 개발자로 구성되어 <br />
                  당신의 역사에서 영화적 순간을 포착합니다.
                </p>
              </div>
            </div>
            <div className={styles.contactInfoBox}>
              <div className={styles.infoWrap}>
                <h6>MemorialOne Story</h6>
                <p>
                  시네모가 제공하는 메모리얼원 서비스는 <br />
                  소중한 기억을 간직할 수 있도록 <br />
                  영화적 순간을 담은 당신의 사진으로 <br />
                  아름답고 따뜻한 영상을 만들고 있습니다.
                </p>
              </div>
            </div>
            <div className={styles.contactInfoBox}>
              <div className={styles.infoWrap}>
                <h6>Cnemo Vision</h6>
                <p>
                  영화적 순간을 만드는 콘텐츠로 <br />
                  새로운 장례 문화를 이끕니다. <br />
                  추모영상, 다큐멘터리, VR, AR 등으로
                  <br />
                  예술과 기술을 결합한 콘텐츠를 만듭니다.
                </p>
              </div>
            </div>
          </div>

          <div className={styles.logoBoxs}>
            <img src={logo1} alt="" />
            <img src={logo2} alt="" />
            <img src={logo3} alt="" />
            <img src={logo4} alt="" />
          </div>

          <div className={styles.contactBox} ref={contactRef}>
            <div>
              <h6>
                협력과 제휴를 통해 함께 성장하며 <br /> 새로운 문화를 형성하고자
                합니다
              </h6>
            </div>

            <div className={styles.dividerWrap}>
              <div className={styles.divider}></div>
            </div>

            <section className={styles.contactInputs}>
              <div>
                <div className={styles.contactInput}>
                  <h3>NAME</h3>
                  <input
                    placeholder="작성자를 입력하세요"
                    type="text"
                    name="contact_name"
                    value={contact.contact_name}
                    onChange={handleChange}
                  />
                </div>
                <div className={styles.contactInput}>
                  <h3>E-MAIL</h3>
                  <input
                    placeholder="이메일을 입력하세요"
                    type="text"
                    name="contact_email"
                    value={contact.contact_email}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div>
                <div className={styles.contactInput}>
                  <h3>MESSAGE</h3>
                  <textarea
                    placeholder="내용을 입력하세요"
                    name="contact_content"
                    value={contact.contact_content}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <button onClick={handleSubmit}>전송</button>
            </section>
          </div>
        </div>
      </main>
    </div>
  );
};

export default LandingMain;
