import HomeNavbar from "@components/Home/HomeNavbar";
import LandingMain from "@components/Home/LandingMain";
import MainFooter from "@components/Home/MainFooter";
import React, { useRef } from "react";

interface HomeProps {}

const Home: React.FC<HomeProps> = () => {
  const projectRef = useRef<HTMLDivElement>(null);
  const contactRef = useRef<HTMLDivElement>(null);

  const handleScrollToProject = () => {
    if (projectRef.current) {
      const top =
        projectRef.current.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({ top, behavior: "smooth" });
    }
  };

  const handleScrollToContact = () => {
    if (contactRef.current) {
      const top =
        contactRef.current.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({ top, behavior: "smooth" });
    }
  };

  const handleScrollBy300px = () => {
    setTimeout(() => {
      window.scrollBy({
        top: contactRef.current?.offsetTop, // 아래로 300px 이동
        behavior: "smooth",
      });
    }, 0);
  };

  return (
    // <div id="home-page">
    //   <button onClick={handleScrollBy300px}>Scroll Down 300px</button>
    //   <div
    //     style={{ height: "2000px", background: "linear-gradient(white, gray)" }}
    //   >
    //     <p ref={contactRef} style={{ marginTop: "1500px" }}>
    //       Scroll to see this text!
    //     </p>
    //   </div>
    // </div>

    <div id="home-page">
      <HomeNavbar
        handleScrollToProject={handleScrollToProject}
        handleScrollToContact={handleScrollToContact}
      />

      <LandingMain projectRef={projectRef} contactRef={contactRef} />

      <MainFooter page="home" />
    </div>
  );
};

export default Home;
