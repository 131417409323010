import React, { useEffect, useState } from "react";
import styles from "./AdminCalDetail.module.css";
import searchStyles from "@assets/css/components/ui/search.module.css";

import { endOfDay, startOfDay, subMonths, subWeeks } from "date-fns";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import PageHeader from "@components/Admin/Design/PageHeader/PageHeader";
import SearchInput from "@components/Admin/Design/Input/SearchInput";
import { useApi } from "@context/ApiContext";
import NumberFormat from "@components/Ui/NumberFormat";
import DefaultCalendar from "@components/Admin/DashBoard/DefaultCalendar";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import AdminLoading from "@pages/Admin/AdminLoading";
import Error from "@components/Service/Ui/Error";
import AdminCalDetailTable from "@components/Admin/Cals/AdminCalDetailTable";
import dayjs from "dayjs";
import Table from "@components/Admin/Table/Table";
import TableBody from "@components/Admin/Table/TableBody";
import TableHeader from "@components/Admin/Table/TableHeader";
import { useAuthContext } from "@context/AuthContext";

interface AdminCalDetailProps {}

const AdminCalDetail: React.FC<AdminCalDetailProps> = ({}) => {
  const { user } = useAuthContext();
  const { FUNERAL_NO } = useParams();
  const location = useLocation();
  const navigator = useNavigate();

  const { adminApi } = useApi();
  const [searchInput, setSearchInput] = useState({
    dm_name: "",
  });

  const [searchFilter, setSearchFilter] = useState({
    startDate: subWeeks(startOfDay(new Date()), 1),
    endDate: endOfDay(new Date()),

    dm_name: "",
  });

  const [dateRange, setDateRange] = useState([
    {
      startDate: subWeeks(startOfDay(new Date()), 1),
      endDate: endOfDay(new Date()),
      key: "selection",
    },
  ]);

  const [quickDateType, setQuickDateType] = useState("");

  const handleDateRangeChanged = (ranges: any) => {
    setDateRange([ranges.selection]);
  };

  const setRange = (type: string) => {
    let startDate;
    let endDate = endOfDay(new Date());

    switch (type) {
      case "6months":
        startDate = subMonths(startOfDay(new Date()), 6);

        break;
      case "1month":
        startDate = subMonths(startOfDay(new Date()), 1);
        break;
      case "1week":
      default:
        startDate = subWeeks(startOfDay(new Date()), 1);
        break;
    }
    setQuickDateType(type);
    setDateRange([{ startDate, endDate, key: "selection" }]);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const startDateParam = searchParams.get("startDate");
    const endDateParam = searchParams.get("endDate");

    const startDate = startDateParam
      ? new Date(startDateParam)
      : subWeeks(startOfDay(new Date()), 1);
    const endDate = endDateParam
      ? new Date(endDateParam)
      : endOfDay(new Date());

    const initialFilter = {
      startDate: startDate,
      endDate: endDate,

      page: searchParams.get("page") || 1,
      pageSize: 10,
    };

    const initialInput = {
      dm_name: searchParams.get("dm_name") || "",
    };

    setSearchInput(initialInput);
    setSearchFilter({ ...initialFilter, ...initialInput });
  }, [location.search]);

  const { isLoading, isError, data } = useQuery(
    ["getFuneralCal", searchFilter],
    () => adminApi.getFuneralCal({ searchFilter, FUNERAL_NO }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      refetchInterval: false,
    }
  );

  const queryClient = useQueryClient();

  const updateFuneralCal = () => {
    queryClient.invalidateQueries(["getFuneralCal"]);
  };

  const handleSearchInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { id, value } = e.target;

    setSearchInput((prev) => ({ ...prev, [id]: value }));
  };

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const queryParams = new URLSearchParams();

    if (dateRange[0].startDate) {
      queryParams.append("startDate", dateRange[0].startDate.toISOString());
    }

    if (dateRange[0].endDate) {
      queryParams.append("endDate", dateRange[0].endDate.toISOString());
    }

    if (searchInput.dm_name) {
      queryParams.append("dm_name", searchInput.dm_name);
    }

    navigator(`?${queryParams.toString()}`);
  };

  const handleReset = () => {
    const startDate = subWeeks(startOfDay(new Date()), 1);
    const endDate = endOfDay(new Date());

    const initialFilter = {
      startDate: startDate,
      endDate: endDate,
      state: "",
      page: 1,
      pageSize: 10,
    };

    const initialInput = {
      dm_name: "",
    };

    setQuickDateType("");
    setSearchInput(initialInput);
    setSearchFilter({ ...initialFilter, ...initialInput });
    setDateRange([{ startDate, endDate, key: "selection" }]);
    navigator("");
  };

  const requestAllFuneralCalDetail = async () => {
    try {
      const { funeralCalList } = await adminApi.getFuneralCal({
        ...searchFilter,
        FUNERAL_NO,
        pageSize: 9999999,
      });

      const excelList = funeralCalList.map((cal: any, idx: number) => {
        const result = {
          No: idx + 1,
          고인명: cal.Event.dm_name,
          제공가: cal.offer_amount,
          "식장 (원)": cal.funeral_amount,
          "동성 (원)": cal.ds_amount,
          "시네모 (원)": cal.cnemo_amount,
          상태: cal.status,
          결제취소일시: cal.cancel_dt,
        };

        return result;
      });

      return excelList;
    } catch (error) {
      console.error(error);
    }
  };

  const handleExportExcel = async () => {
    try {
      const excel = (await requestAllFuneralCalDetail()) as any;

      const Xlsx = require("xlsx-js-style");
      const workBook = Xlsx.utils.book_new();
      const workSheet = Xlsx.utils.aoa_to_sheet([]);
      const border = {
        top: { style: "thin", color: { rgb: "000000" } }, // 위쪽 테두리
        bottom: { style: "thin", color: { rgb: "000000" } }, // 아래쪽 테두리
        left: { style: "thin", color: { rgb: "000000" } }, // 왼쪽 테두리
        right: { style: "thin", color: { rgb: "000000" } }, // 오른쪽 테두리
      };
      // 엑셀 스타일을 적용하기 위한 설정
      const headerStyle = {
        font: {
          name: "돋움",
          sz: 8,
        },
        fill: {
          fgColor: { rgb: "FFC247" }, // 셀 배경색 (노란색)
        },
        border,
      };

      const cellStyle = {
        font: {
          name: "돋움",
          sz: 8,
        },
        border,
      };

      const redBgCellStyle = {
        font: {
          name: "돋움",
          sz: 8,
        },
        fill: {
          fgColor: { rgb: "FF0000" }, // 셀 배경색 (빨간색)
        },
        border,
      };

      // 데이터 추가
      Xlsx.utils.sheet_add_json(workSheet, excel, {
        origin: -1,
      });
      const range = Xlsx.utils.decode_range(workSheet["!ref"]);

      // 첫 번째 줄에 스타일 적용
      const headerRange = {
        s: { r: 0, c: 0 },
        e: { r: 0, c: range.e.c },
      };
      for (let colNum = headerRange.s.c; colNum <= headerRange.e.c; colNum++) {
        const cellAddress = Xlsx.utils.encode_cell({
          r: headerRange.s.r,
          c: colNum,
        });
        const cell = workSheet[cellAddress];
        cell.s = headerStyle;
      }

      // 나머지 셀에는 기본 스타일 적용
      for (let rowNum = range.s.r + 1; rowNum <= range.e.r; rowNum++) {
        for (let colNum = range.s.c; colNum <= range.e.c; colNum++) {
          const cellAddress = Xlsx.utils.encode_cell({
            r: rowNum,
            c: colNum,
          });
          const cell = workSheet[cellAddress];

          // status가 'cancelled'인 경우 빨간색 배경 스타일 적용
          if (excel[rowNum - 1] && excel[rowNum - 1].주문상태 === "결제취소") {
            cell.s = redBgCellStyle;
          } else {
            cell.s = cellStyle;
          }

          cell.b = cellStyle.border; // 테두리 적용
        }
      }

      Xlsx.utils.book_append_sheet(
        workBook,
        workSheet,
        `${dayjs(searchFilter.startDate).format("YYYY-MM-DD")} ~ ${dayjs(
          searchFilter.endDate
        ).format("YYYY-MM-DD")}`
      );

      Xlsx.writeFile(
        workBook,
        `추모영상 정산_${data?.funeral?.FUNERAL_NAME}(${dayjs(
          searchFilter.startDate
        ).format("YYYY-MM-DD")} ~ ${dayjs(searchFilter.endDate).format(
          "YYYY-MM-DD"
        )}).xlsx`
      );
    } catch (error) {
      console.error(error);
    }
  };

  if (isLoading) return <AdminLoading />;
  if (isError) return <Error />;

  return (
    <div>
      <PageHeader
        title={`정산 관리 / ${data?.funeral?.FUNERAL_NAME}`}
        desc={`${data?.funeral?.FUNERAL_NAME}의 상세 정산 정보 목록입니다`}
        addButton="목록으로"
        handleAddButton={() => navigator("/admin/dashboard/cals")}
      />

      <section className={styles.funcSection}>
        <SearchInput
          handleSearch={handleSearch}
          handleReset={handleReset}
          isExcel
          handleExportExcel={handleExportExcel}
        >
          <div className={searchStyles.dateWrap}>
            <div className={searchStyles.leftWrap}>
              <div className={searchStyles.quickButtons}>
                <button
                  className={`${
                    quickDateType === "6months" && searchStyles.active
                  }`}
                  type="button"
                  onClick={() => setRange("6months")}
                >
                  6개월
                </button>
                <button
                  className={`${
                    quickDateType === "1month" && searchStyles.active
                  }`}
                  type="button"
                  onClick={() => setRange("1month")}
                >
                  1개월
                </button>
                <button
                  className={`${
                    quickDateType === "1week" && searchStyles.active
                  }`}
                  type="button"
                  onClick={() => setRange("1week")}
                >
                  1주
                </button>
              </div>

              <DefaultCalendar
                dateRange={dateRange}
                handleDateRangeChanged={handleDateRangeChanged}
              />
            </div>
          </div>

          {/* <div className={searchStyles.filterItemWraps}>
            <div className={searchStyles.filterItem}>
              <label htmlFor="dm_name">고인명</label>
              <input
                id="dm_name"
                value={searchInput.dm_name}
                type="text"
                placeholder="고인명을 입력하세요"
                onChange={handleSearchInput}
              />
            </div>
          </div> */}
        </SearchInput>
      </section>

      <main className={styles.mainWrap}>
        {[99, 89].includes(user.LV) ? (
          <>
            <Table className={styles.roomTableWrap}>
              <TableBody>
                <TableHeader>
                  <th className={styles.exSmallTh}>총 주문 수량</th>
                  {data?.totalFuneralCalInfo?.roomOrderCounts.map(
                    (room: any) => (
                      <th className={styles.smallTh} key={room.id}>
                        {room.room_name || "-"}
                      </th>
                    )
                  )}
                </TableHeader>

                <tr>
                  <td>
                    <NumberFormat
                      number={data?.totalFuneralCalInfo.totalCalCount}
                    />
                    건
                  </td>
                  {data?.totalFuneralCalInfo?.roomOrderCounts.map(
                    (room: any) => (
                      <td key={room.id}>
                        <NumberFormat number={room.order_count} />건
                      </td>
                    )
                  )}
                </tr>
              </TableBody>
            </Table>
            <Table>
              <TableBody>
                <TableHeader>
                  <th className={styles.smallTh}>총 거래액</th>
                  <th className={styles.smallTh}>총 순거래액</th>
                  {/* <th className={styles.smallTh}>총 거래취소액</th> */}
                  <th className={styles.middleTh}>총 식장 지원금</th>
                  <th className={styles.middleTh}>총 동성 지원금</th>
                  <th className={styles.middleTh}>총 시네모 지원금</th>
                </TableHeader>

                <tr>
                  <td>
                    <NumberFormat
                      number={data?.totalFuneralCalInfo.totalOfferAmount}
                    />
                    원
                  </td>
                  <td>
                    <NumberFormat
                      number={data?.totalFuneralCalInfo.totalRealOfferAmount}
                    />
                    원
                  </td>
                  {/* <td>
                    <NumberFormat
                      number={
                        data?.totalFuneralCalInfo.totalCancelledOfferAmount
                      }
                    />
                    원
                  </td> */}
                  <td>
                    <NumberFormat
                      number={data?.totalFuneralCalInfo.totalFuneralAmount}
                    />
                    원
                  </td>
                  <td>
                    <NumberFormat
                      number={data?.totalFuneralCalInfo.totalDsAmount}
                    />
                    원
                  </td>
                  <td>
                    <NumberFormat
                      number={data?.totalFuneralCalInfo.totalCnemoAmount}
                    />
                    원
                  </td>
                </tr>
              </TableBody>
            </Table>
          </>
        ) : (
          <Table className={styles.roomTableWrap}>
            <TableBody>
              <TableHeader>
                <th className={styles.exSmallTh}>총 주문 수량</th>
                {data?.totalFuneralCalInfo?.roomOrderCounts.map((room: any) => (
                  <th className={styles.smallTh} key={room.id}>
                    {room.room_name || "-"}
                  </th>
                ))}
                {/* <th className={styles.smallTh}>총 거래액</th>
                <th className={styles.smallTh}>총 순거래액</th>
                <th className={styles.smallTh}>총 거래취소액</th>
                <th className={styles.middleTh}>총 식장 지원금</th>
                <th className={styles.middleTh}>총 동성 지원금</th>
                <th className={styles.middleTh}>총 시네모 지원금</th> */}
              </TableHeader>

              <tr>
                <td>
                  <NumberFormat
                    number={data?.totalFuneralCalInfo.totalCalCount}
                  />
                  건
                </td>
                {data?.totalFuneralCalInfo?.roomOrderCounts.map((room: any) => (
                  <td key={room.id}>
                    <NumberFormat number={room.order_count} />건
                  </td>
                ))}
              </tr>
            </TableBody>
          </Table>
        )}
        <AdminCalDetailTable
          LV={user.LV}
          funeralCalList={data?.funeralCalList}
          totalPages={data?.totalPages || 0}
          location={location}
          updateFuneralCal={updateFuneralCal}
        />
      </main>
    </div>
  );
};

export default AdminCalDetail;
