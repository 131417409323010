export default class AdminApi {
  constructor(apiClient) {
    this.apiClient = apiClient;
  }
  /* 어드민 관련 API */
  // 로그인
  async login(params) {
    return this.apiClient.login(params).then((res) => {
      if (!res) return;
      const {
        data: { statusCode, message, result },
        data,
      } = res;

      if (statusCode !== 200) {
        alert(message);
        return null;
      }

      localStorage.setItem("access_token", "Bearer " + result.token);

      return data;
    });
  }

  // 상태 변경
  async onUserStateChange(callback) {
    return this.apiClient.getAuth().then((res) => {
      if (!res) return;

      console.log(res, "res");

      const {
        data: { statusCode, message, result },
      } = res;

      if (statusCode === 201) {
        alert(message);
        callback(null);
        return;
      }

      callback(result.admin);
    });
  }

  async getDashboard() {
    return this.apiClient.getDashboard().then((res) => {
      const { data } = res;

      if (data.statusCode !== 200) {
        alert(data.message);
        return null;
      }

      return data.result;
    });
  }

  async getSalesChartData(params) {
    return this.apiClient.getSalesChartData(params).then((res) => {
      const { data } = res;

      if (data.statusCode !== 200) {
        alert(data.message);
        return null;
      }

      return data.result;
    });
  }

  // 어드민 목록 불러오기
  async getAdmins(params) {
    return this.apiClient
      .getAdmins(params)
      .then((res) => {
        const { data } = res;

        return data.result.admins;
      })
      .catch((err) => console.error(err));
  }

  // 어드민 생성 및 수정
  async saveOrUpdateAdminUser(params) {
    return this.apiClient
      .saveOrUpdateAdminUser(params)
      .then((res) => {
        const { data } = res;

        if (data.statusCode !== 200) {
          alert(data.message);
          return null;
        }

        return data.result.adminUser;
      })
      .catch((err) => console.error(err));
  }

  // 어드민 그룹 목록 불러오기
  async getAdminGroups() {
    return this.apiClient
      .getAdminGroups()
      .then((res) => {
        const { data } = res;

        return data.result.adminGroups;
      })
      .catch((err) => console.error(err));
  }

  // 어드민 그룹 생성 및 수정
  async saveOrUpdateAdminGroup(params) {
    return this.apiClient
      .saveOrUpdateAdminGroup(params)
      .then((res) => {
        const { data } = res;

        if (data.statusCode !== 200) {
          alert(data.message);
          return null;
        }

        return data.result.adminGroup;
      })
      .catch((err) => console.error(err));
  }

  /* 템플릿 관련 API */
  // 템플릿 카테고리 불러오기
  async getTemplateCategorys() {
    return this.apiClient
      .getTemplateCategorys()
      .then((res) => {
        const { data } = res;

        return data.result.templateCategorys;
      })
      .catch((err) => console.error(err));
  }

  //템플릿 카테고리 생성 및 수정
  async saveOrUpdateTemplateCategory(params) {
    return this.apiClient
      .saveOrUpdateTemplateCategory(params)
      .then((res) => {
        const { data } = res;

        if (data.statusCode !== 200) {
          alert(data.message);
          return null;
        }

        return data.result.templateCategory;
      })
      .catch((err) => console.error(err));
  }

  // 템플릿 생성 및 수정
  async saveOrUpdateTemplate(params) {
    return this.apiClient
      .saveOrUpdateTemplate(params)
      .then((res) => {
        const { data } = res;

        if (data.statusCode !== 200) {
          alert(data.message);
          return null;
        }

        return data.result.template;
      })
      .catch((err) => console.error(err));
  }

  // 템플릿 자막 & 이미지 정보 수정
  async updateTempSubAndImage(params) {
    return this.apiClient
      .updateTempSubAndImage(params)
      .then((res) => {
        const { data } = res;

        if (data.statusCode !== 200) {
          alert(data.message);
          return null;
        }

        return data.result.template;
      })
      .catch((err) => console.error(err));
  }

  /* 행사 관련 API */
  // 행사 목록 불러오기
  async getEvents(params) {
    return this.apiClient
      .getAdminEvents(params)
      .then((res) => {
        const { data } = res;

        return data.result;
      })
      .catch((err) => console.error(err));
  }
  // 행사 상태 변경
  async updateEventState(params) {
    return this.apiClient
      .updateEventState(params)
      .then((res) => {
        const { data } = res;
        if (data.statusCode !== 200) {
          alert(data.message);
          return null;
        }

        return data.result;
      })
      .catch((err) => {
        console.error(err);
        return null;
      });
  }

  // 행사 저장 및 수정
  async saveOrUpdateEvent(params) {
    return this.apiClient
      .saveOrUpdateEvent(params)
      .then((res) => {
        const { data } = res;
        if (data.statusCode !== 200) {
          alert(data.message);
          return null;
        }

        return data.result;
      })
      .catch((err) => {
        console.error(err);
        return null;
      });
  }

  // 행사 확정
  async updateEventConfirmed(params) {
    return this.apiClient
      .updateEventConfirmed(params)
      .then((res) => {
        const { data } = res;
        if (data.statusCode !== 200) {
          alert(data.message);
          return null;
        }

        return data.result;
      })
      .catch((err) => {
        console.error(err);
        return null;
      });
  }

  // 행사 상세 정보 가져오기
  async getEvent(event_no) {
    return this.apiClient
      .getAdminEvent(event_no)
      .then((res) => {
        const { data } = res;

        return data.result.event;
      })
      .catch((err) => console.error(err));
  }

  // 행사 자막 수정
  async updateEventSubtitle(params) {
    return this.apiClient
      .updateEventSubtitle(params)
      .then((res) => {
        const { data } = res;

        if (data.statusCode !== 200) {
          alert(data.message);
          return null;
        }
        return data.result.eventSubtitle;
      })
      .catch((err) => console.error(err));
  }

  // 행사 사진 삭제
  async deleteEventImages(params) {
    return this.apiClient
      .deleteEventImages(params)
      .then((res) => {
        const { data } = res;

        if (data.statusCode !== 200) {
          alert(data.message);
          return null;
        }

        return true;
      })
      .catch((err) => console.error(err));
  }

  /* 렌더링 관련 API */
  // 렌더링 대기열 목록 가져오기
  async getQueues(params) {
    return this.apiClient
      .getQueues(params)
      .then((res) => {
        const { data } = res;

        if (data.statusCode !== 200) {
          alert(data.message);
          return null;
        }

        return data.result;
      })
      .catch((err) => console.error(err));
  }

  // 렌더링 상세 정보 가져오기
  async getQueue(queueId) {
    return this.apiClient
      .getQueue(queueId)
      .then((res) => {
        const { data } = res;

        if (data.statusCode !== 200) {
          alert(data.message);
          return null;
        }

        return data.result.queue;
      })
      .catch((err) => console.error(err));
  }

  // 렌더링 시작하기
  async startQueue(params) {
    return this.apiClient
      .startQueue(params)
      .then((res) => {
        const { data } = res;

        if (data.statusCode !== 200) {
          alert(data.message);
          return null;
        }

        return true;
      })
      .catch((err) => console.error(err));
  }
  // 렌더링 취소하기
  async cancelQueue(params) {
    return this.apiClient
      .cancelQueue(params)
      .then((res) => {
        const { data } = res;

        if (data.statusCode !== 200) {
          alert(data.message);
          return null;
        }

        return true;
      })
      .catch((err) => console.error(err));
  }

  // 렌더링 저장 및 업데이트
  async saveOrUpdateQueue(params) {
    return this.apiClient.saveOrUpdateQueue(params).then((res) => {
      const { data } = res;
    });
  }

  // 렌더링 다시 시도
  async retryQueue(params) {
    return this.apiClient
      .retryQueue(params)
      .then((res) => {
        const { data } = res;

        if (data.statusCode !== 200) {
          alert(data.message);
          return null;
        }

        return true;
      })
      .catch((err) => console.error(err));
  }

  /* 주문 관련 API */
  // 주문 목록 가져오기
  async getOrders(params) {
    return this.apiClient
      .getOrders(params)
      .then((res) => {
        const { data } = res;

        if (data.statusCode !== 200) {
          alert(data.message);
          return null;
        }

        return data.result;
      })
      .catch((err) => console.error(err));
  }

  // 배송관련 정보 가져오기
  async getDelivery(params) {
    return this.apiClient
      .getDelivery(params)
      .then((res) => {
        const { data } = res;

        if (data.statusCode !== 200) {
          alert(data.message);
          return null;
        }

        return data.result;
      })
      .catch((err) => console.error(err));
  }

  async updateDelivery(params) {
    return this.apiClient
      .updateDelivery(params)
      .then((res) => {
        const { data } = res;

        if (data.statusCode !== 200) {
          alert(data.message);
          return null;
        }

        return data.result;
      })
      .catch((err) => console.error(err));
  }

  // 영상 미확정 안내 문자 발송
  async notifyUnconfirmedStatus(params) {
    return this.apiClient
      .notifyUnconfirmedStatus(params)
      .then((res) => {
        const { data } = res;

        if (data.statusCode !== 200) {
          alert(data.message);
          return null;
        }

        return data.result;
      })
      .catch((err) => console.error(err));
  }

  // 관리비 정산 목록 가져오기
  async getOrderCals(params) {
    return this.apiClient
      .getOrderCals(params)
      .then((res) => {
        const { data } = res;

        if (data.statusCode !== 200) {
          alert(data.message);
          return null;
        }

        return data.result;
      })
      .catch((err) => console.error(err));
  }

  /* 상품 관련 API */
  // 상품 목록 불러오기
  async getShops(params) {
    return this.apiClient
      .getShops(params)
      .then((res) => {
        const { data } = res;

        return data.result.shops;
      })
      .catch((err) => console.error(err));
  }

  // 상품 생성 및 수정
  async saveOrUpdateShop(params) {
    return this.apiClient
      .saveOrUpdateShop(params)
      .then((res) => {
        const { data } = res;

        if (data.statusCode !== 200) {
          alert(data.message);
          return null;
        }

        return data.result.shop;
      })
      .catch((err) => console.error(err));
  }

  // 상품 카테고리 목록 불러오기
  async getShopCategorys() {
    return this.apiClient
      .getShopCategorys()
      .then((res) => {
        const { data } = res;

        return data.result.shopCategorys;
      })
      .catch((err) => console.error(err));
  }

  // 상품 카테고리 생성 및 수정
  async saveOrUpdateShopCategory(params) {
    return this.apiClient
      .saveOrUpdateShopCategory(params)
      .then((res) => {
        const { data } = res;

        if (data.statusCode !== 200) {
          alert(data.message);
          return null;
        }

        return data.result.shopCategory;
      })
      .catch((err) => console.error(err));
  }

  // 상품 상세 이미지 생성 및 수정
  async saveOrUpdateShopDetailImage(params) {
    return this.apiClient
      .saveOrUpdateShopDetailImage(params)
      .then((res) => {
        const { data } = res;

        if (data.statusCode !== 200) {
          alert(data.message);
          return null;
        }

        return data.result;
      })
      .catch((err) => console.error(err));
  }

  /* 장례식장 관련 API */
  // 장례식장 목록 불러오기
  async getFunerals(params) {
    return this.apiClient
      .getAdminFunerals(params)
      .then((res) => {
        const { data } = res;

        if (data.statusCode !== 200) {
          alert(data.message);
          return null;
        }

        return data.result;
      })
      .catch((err) => console.error(err));
  }

  async saveOrUpdateFuneral(params) {
    return this.apiClient
      .saveOrUpdateFuneral(params)
      .then((res) => {
        const { data } = res;

        if (data.statusCode !== 200) {
          alert(data.message);
          return null;
        }

        return data.result;
      })
      .catch((err) => console.error(err));
  }

  // 장례식장 삭제하기
  async deleteFuneral(funeral_no) {
    return this.apiClient
      .deleteFuneral(funeral_no)
      .then((res) => {
        const { data } = res;

        if (data.statusCode !== 200) {
          alert(data.message);
          return null;
        }

        return data.result;
      })
      .catch((err) => console.error(err));
  }

  async getFuneralShop(funeral_no) {
    return this.apiClient
      .getFuneralShop(funeral_no)
      .then((res) => {
        const { data } = res;

        if (data.statusCode !== 200) {
          alert(data.message);
          return null;
        }

        return data.result;
      })
      .catch((err) => console.error(err));
  }

  async saveOrUpdateFuneralShop(params) {
    return this.apiClient
      .saveOrUpdateFuneralShop(params)
      .then((res) => {
        const { data } = res;

        if (data.statusCode !== 200) {
          alert(data.message);
          return null;
        }

        return data.result;
      })
      .catch((err) => console.error(err));
  }

  async saveOrUpdateFuneralPayback(params) {
    return this.apiClient
      .saveOrUpdateFuneralPayback(params)
      .then((res) => {
        const { data } = res;

        if (data.statusCode !== 200) {
          alert(data.message);
          return null;
        }
        return data.result;
      })
      .catch((err) => console.error(err));
  }

  // 정산 정보 가져오기
  async getFuneralCals(params) {
    return this.apiClient
      .getFuneralCals(params)
      .then((res) => {
        const { data } = res;

        if (data.statusCode !== 200) {
          alert(data.message);
          return null;
        }

        return data.result;
      })
      .catch((err) => console.error(err));
  }

  async getFuneralCal(params) {
    return this.apiClient
      .getFuneralCal(params)
      .then((res) => {
        const { data } = res;

        console.log(data);
        if (data.statusCode !== 200) {
          alert(data.message);
          return null;
        }

        return data.result;
      })
      .catch((err) => console.error(err));
  }

  // get contacts
  async getContacts(params) {
    return this.apiClient.getContacts(params).then((res) => {
      const { data } = res;

      if (data.statusCode !== 200) {
        alert(data.message);
        return null;
      }

      return data.result;
    });
  }

  // save contact
  async saveOrUpdateContact(params) {
    return this.apiClient.saveOrUpdateContact(params).then((res) => {
      const { data } = res;

      if (data.statusCode !== 200) {
        alert(data.message);
        return null;
      }

      return true;
    });
  }

  // get feedbacks
  async getAdminFeedbacks(params) {
    return this.apiClient.getAdminFeedbacks(params).then((res) => {
      const { data } = res;

      if (data.statusCode !== 200) {
        alert(data.message);
        return null;
      }

      return data.result;
    });
  }

  async confirmFeedback(id) {
    return this.apiClient.confirmFeedback(id).then((res) => {
      const { data } = res;

      if (data.statusCode !== 200) {
        alert(data.message);
        return null;
      }

      return true;
    });
  }

  async deleteFeedback(id) {
    return this.apiClient.deleteFeedback(id).then((res) => {
      const { data } = res;

      if (data.statusCode !== 200) {
        alert(data.message);
        return null;
      }

      return true;
    });
  }
}
