import React, { useState } from "react";
import styles from "./HomeNavbar.module.css";
import { Link, useLocation } from "react-router-dom";

interface HomeNavbarProps {
  handleScrollToProject: () => void;
  handleScrollToContact: () => void;
}

const HomeNavbar: React.FC<HomeNavbarProps> = ({
  handleScrollToProject,
  handleScrollToContact,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  React.useEffect(() => {
    closeMenu();
  }, [location]);

  return (
    <header className={styles.header}>
      <nav>
        <div className={styles.hamburger} onClick={toggleMenu}>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <Link to="/">메모리얼원</Link>

        <ul className={isOpen ? styles.active : ""}>
          <li onClick={handleScrollToProject}>
            <Link to="#">추모영상</Link>
          </li>
          <li onClick={handleScrollToContact}>
            <Link to="#">제휴문의</Link>
          </li>
          <li>
            <Link to="http://pf.kakao.com/_tdxjxmK/chat" target="_blank">
              제작문의
            </Link>
          </li>
          <li>
            <Link to="/admin" target="_blank">
              관리자
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default HomeNavbar;
