import React, { useEffect, useState } from "react";
import styles from "./AdminCals.module.css";
import searchStyles from "@assets/css/components/ui/search.module.css";

import PageHeader from "@components/Admin/Design/PageHeader/PageHeader";
import SearchInput from "@components/Admin/Design/Input/SearchInput";

import { useQuery, useQueryClient } from "@tanstack/react-query";
import { endOfDay, startOfDay, subMonths, subWeeks } from "date-fns";

import Error from "@components/Service/Ui/Error";
import { useApi } from "@context/ApiContext";
import AdminEventTable from "@components/Admin/Event/AdminEventTable";
import { useLocation, useNavigate } from "react-router-dom";
import AdminLoading from "@pages/Admin/AdminLoading";
import DefaultCalendar from "@components/Admin/DashBoard/DefaultCalendar";
import AdminCalsTable from "@components/Admin/Cals/AdminCalsTable";
import NumberFormat from "@components/Ui/NumberFormat";
import dayjs from "dayjs";
import Table from "@components/Admin/Table/Table";
import TableBody from "@components/Admin/Table/TableBody";
import TableHeader from "@components/Admin/Table/TableHeader";
import { useAuthContext } from "@context/AuthContext";

interface AdminCalsProps {}

const AdminCals: React.FC<AdminCalsProps> = ({}) => {
  const { user } = useAuthContext();
  const location = useLocation();
  const navigator = useNavigate();

  const { adminApi } = useApi();
  const [searchInput, setSearchInput] = useState({
    FUNERAL_NAME: "",
    ADDRESS: "",
    SIDO_GUNGU: "",
  });

  const [searchFilter, setSearchFilter] = useState({
    startDate: subWeeks(startOfDay(new Date()), 1),
    endDate: endOfDay(new Date()),

    FUNERAL_NAME: "",
    ADDRESS: "",
    SIDO_GUNGU: "",
  });

  const [dateRange, setDateRange] = useState([
    {
      startDate: subWeeks(startOfDay(new Date()), 1),
      endDate: endOfDay(new Date()),
      key: "selection",
    },
  ]);

  const [quickDateType, setQuickDateType] = useState("");

  const handleDateRangeChanged = (ranges: any) => {
    setDateRange([ranges.selection]);
  };

  const setRange = (type: string) => {
    let startDate;
    let endDate = endOfDay(new Date());

    switch (type) {
      case "6months":
        startDate = subMonths(startOfDay(new Date()), 6);

        break;
      case "1month":
        startDate = subMonths(startOfDay(new Date()), 1);
        break;
      case "1week":
      default:
        startDate = subWeeks(startOfDay(new Date()), 1);
        break;
    }
    setQuickDateType(type);
    setDateRange([{ startDate, endDate, key: "selection" }]);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const startDateParam = searchParams.get("startDate");
    const endDateParam = searchParams.get("endDate");

    const startDate = startDateParam
      ? new Date(startDateParam)
      : subWeeks(startOfDay(new Date()), 1);
    const endDate = endDateParam
      ? new Date(endDateParam)
      : endOfDay(new Date());

    const initialFilter = {
      startDate: startDate,
      endDate: endDate,

      page: searchParams.get("page") || 1,
      pageSize: 10,
    };

    const initialInput = {
      FUNERAL_NAME: searchParams.get("FUNERAL_NAME") || "",
      ADDRESS: searchParams.get("ADDRESS") || "",
      SIDO_GUNGU: searchParams.get("SIDO_GUNGU") || "",
    };

    setSearchInput(initialInput);
    setSearchFilter({ ...initialFilter, ...initialInput });
  }, [location.search]);

  const { isLoading, isError, data } = useQuery(
    ["getFuneralCals", searchFilter],
    () => adminApi.getFuneralCals({ searchFilter }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      refetchInterval: false,
    }
  );

  const queryClient = useQueryClient();

  const updateFuneralCals = () => {
    queryClient.invalidateQueries(["getFuneralCals"]);
  };

  const handleSearchInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { id, value } = e.target;

    setSearchInput((prev) => ({ ...prev, [id]: value }));
  };

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const queryParams = new URLSearchParams();

    if (dateRange[0].startDate) {
      queryParams.append("startDate", dateRange[0].startDate.toISOString());
    }

    if (dateRange[0].endDate) {
      queryParams.append("endDate", dateRange[0].endDate.toISOString());
    }

    if (searchInput.FUNERAL_NAME) {
      queryParams.append("FUNERAL_NAME", searchInput.FUNERAL_NAME);
    }

    if (searchInput.ADDRESS) {
      queryParams.append("ADDRESS", searchInput.ADDRESS);
    }

    if (searchInput.SIDO_GUNGU) {
      queryParams.append("SIDO_GUNGU", searchInput.SIDO_GUNGU);
    }

    navigator(`?${queryParams.toString()}`);
  };

  const handleReset = () => {
    const startDate = subWeeks(startOfDay(new Date()), 1);
    const endDate = endOfDay(new Date());

    const initialFilter = {
      startDate: startDate,
      endDate: endDate,
      state: "",
      page: 1,
      pageSize: 10,
    };

    const initialInput = {
      FUNERAL_NAME: "",
      ADDRESS: "",
      SIDO_GUNGU: "",
    };

    setQuickDateType("");
    setSearchInput(initialInput);
    setSearchFilter({ ...initialFilter, ...initialInput });
    setDateRange([{ startDate, endDate, key: "selection" }]);
    navigator("");
  };

  const requestGetAllFuneralCal = async () => {
    try {
      const { funeralCalList } = await adminApi.getFuneralCals({
        searchFilter,
        pageSize: 9999999,
      });

      const excelList = funeralCalList.map((funeral: any, idx: number) => {
        const funeralCals = funeral.FuneralCals;
        const orderAmount = funeralCals?.reduce(
          (accumulator: any, cal: any) =>
            accumulator + parseInt(cal.offer_amount),
          0
        ); // 총 결제 금액

        const cancellledOrderCount = funeral.FuneralCals?.filter(
          (v: any) => v.status === "cancelled"
        ).length; // 취소 건수

        const realOrderAmount = funeral.FuneralCals?.filter(
          (v: any) => v.status === "paid"
        )?.reduce(
          (accumulator: any, cal: any) =>
            accumulator + parseInt(cal.offer_amount),
          0
        ); // 총 순 거래액,

        const cancelledOrderAmount = funeral.FuneralCals?.filter(
          (v: any) => v.status === "cancelled"
        )?.reduce(
          (accumulator: any, cal: any) =>
            accumulator + parseInt(cal.offer_amount),
          0
        ); // 총 취소액

        const result = {
          No: idx + 1,
          FUNERAL_NO: funeral.FUNERAL_NO,
          장례식장명: funeral.FUNERAL_NAME,
          제작건수: funeralCals?.length,
          취소건수: cancellledOrderCount,
          "총 거래액(원)": orderAmount,
          "순 거래액(원)": realOrderAmount,
          // "거래 취소액(원)": cancelledOrderAmount,
          "식장 (원)": Math.floor(
            funeralCals
              ?.filter((cal: any) => cal.status === "paid")
              .reduce(
                (accumulator: any, cal: any) =>
                  accumulator + parseInt(cal.funeral_amount),
                0
              )
          ),
          "동성 (원)": funeralCals
            ?.filter((cal: any) => cal.status === "paid")
            .reduce(
              (accumulator: any, cal: any) =>
                accumulator + parseInt(cal.ds_amount),
              0
            ),
          "시네모 (원)": funeralCals
            ?.filter((cal: any) => cal.status === "paid")
            .reduce(
              (accumulator: any, cal: any) =>
                accumulator + parseInt(cal.cnemo_amount),
              0
            ),
        };

        return result;
      });

      return excelList;
    } catch (error) {
      console.error(error);
      alert("excel 전체 데이터 불러오기를 실패하였습니다");
    }
  };

  const handleExportExcel = async () => {
    try {
      const excel = await requestGetAllFuneralCal();

      const Xlsx = require("xlsx-js-style");
      const workBook = Xlsx.utils.book_new();
      const workSheet = Xlsx.utils.aoa_to_sheet([]);
      const border = {
        top: { style: "thin", color: { rgb: "000000" } }, // 위쪽 테두리
        bottom: { style: "thin", color: { rgb: "000000" } }, // 아래쪽 테두리
        left: { style: "thin", color: { rgb: "000000" } }, // 왼쪽 테두리
        right: { style: "thin", color: { rgb: "000000" } }, // 오른쪽 테두리
      };
      // 엑셀 스타일을 적용하기 위한 설정
      const headerStyle = {
        font: {
          name: "돋움",
          sz: 8,
        },
        fill: {
          fgColor: { rgb: "FFC247" }, // 셀 배경색 (노란색)
        },
        border,
      };

      const cellStyle = {
        font: {
          name: "돋움",
          sz: 8,
        },
        border,
        // fill: {
        //   fgColor: { rgb: "FFFF00" } // 셀 배경색 (노란색)
        // }
      };

      // 데이터 추가
      Xlsx.utils.sheet_add_json(workSheet, excel, {
        origin: -1,
      });
      const range = Xlsx.utils.decode_range(workSheet["!ref"]);

      // 첫 번째 줄에 스타일 적용
      const headerRange = { s: { r: 0, c: 0 }, e: { r: 0, c: range.e.c } };
      for (let colNum = headerRange.s.c; colNum <= headerRange.e.c; colNum++) {
        const cellAddress = Xlsx.utils.encode_cell({
          r: headerRange.s.r,
          c: colNum,
        });
        const cell = workSheet[cellAddress];
        cell.s = headerStyle;
      }

      // 나머지 셀에는 기본 스타일 적용
      for (let rowNum = range.s.r + 1; rowNum <= range.e.r; rowNum++) {
        for (let colNum = range.s.c; colNum <= range.e.c; colNum++) {
          const cellAddress = Xlsx.utils.encode_cell({
            r: rowNum,
            c: colNum,
          });
          const cell = workSheet[cellAddress];
          cell.s = cellStyle;
          cell.b = cellStyle.border; // 테두리 적용
        }
      }

      Xlsx.utils.book_append_sheet(
        workBook,
        workSheet,
        `${dayjs(searchFilter.startDate).format("YYYY-MM-DD")} ~ ${dayjs(
          searchFilter.endDate
        ).format("YYYY-MM-DD")}`
      );

      Xlsx.writeFile(
        workBook,
        `추모영상 정산(${dayjs(searchFilter.startDate).format(
          "YYYY-MM-DD"
        )} ~ ${dayjs(searchFilter.endDate).format("YYYY-MM-DD")}).xlsx`
      );
    } catch (error) {
      console.error(error);
    }
  };

  if (isLoading) return <AdminLoading />;
  if (isError) return <Error />;

  return (
    <div>
      <PageHeader title="정산 관리" desc="정산 목록을 관리합니다." />

      <section className={styles.funcSection}>
        <SearchInput
          handleSearch={handleSearch}
          handleReset={handleReset}
          isExcel
          handleExportExcel={handleExportExcel}
        >
          <div className={searchStyles.dateWrap}>
            <div className={searchStyles.leftWrap}>
              <div className={searchStyles.quickButtons}>
                <button
                  className={`${
                    quickDateType === "6months" && searchStyles.active
                  }`}
                  type="button"
                  onClick={() => setRange("6months")}
                >
                  6개월
                </button>
                <button
                  className={`${
                    quickDateType === "1month" && searchStyles.active
                  }`}
                  type="button"
                  onClick={() => setRange("1month")}
                >
                  1개월
                </button>
                <button
                  className={`${
                    quickDateType === "1week" && searchStyles.active
                  }`}
                  type="button"
                  onClick={() => setRange("1week")}
                >
                  1주
                </button>
              </div>

              <DefaultCalendar
                dateRange={dateRange}
                handleDateRangeChanged={handleDateRangeChanged}
              />
            </div>
          </div>

          <div className={searchStyles.filterItemWraps}>
            <div className={searchStyles.filterItem}>
              <label htmlFor="FUNERAL_NAME">식장명</label>
              <input
                id="FUNERAL_NAME"
                value={searchInput.FUNERAL_NAME}
                type="text"
                placeholder="장례식장명을 입력하세요"
                onChange={handleSearchInput}
              />
            </div>

            <div className={searchStyles.filterItem}>
              <label htmlFor="ADDRESS">주소명</label>
              <input
                id="ADDRESS"
                value={searchInput.ADDRESS}
                type="text"
                placeholder="주소명을 입력하세요"
                onChange={handleSearchInput}
              />
            </div>
            <div className={searchStyles.filterItem}>
              <label htmlFor="SIDO_GUNGU">시/군/구</label>
              <input
                id="SIDO_GUNGU"
                value={searchInput.SIDO_GUNGU}
                type="text"
                placeholder="시/군/구로 검색합니다"
                onChange={handleSearchInput}
              />
            </div>
          </div>
        </SearchInput>
      </section>

      <main className={styles.mainWrap}>
        {[99, 89].includes(Number(user.LV)) ? (
          <Table>
            <TableBody>
              <TableHeader>
                <th className={styles.exSmallTh}>총 주문 수량</th>
                <th className={styles.smallTh}>총 거래액</th>
                <th className={styles.smallTh}>총 순거래액</th>
                {/* <th className={styles.smallTh}>총 거래취소액</th> */}
                <th className={styles.middleTh}>총 식장 지원금</th>
                <th className={styles.middleTh}>총 동성 지원금</th>
                <th className={styles.middleTh}>총 시네모 지원금</th>
              </TableHeader>

              <tr>
                <td>
                  <NumberFormat
                    number={data?.totalFuneralCalInfo.totalCalCount}
                  />
                </td>
                <td>
                  <NumberFormat
                    number={data?.totalFuneralCalInfo.totalOfferAmount}
                  />
                  원
                </td>
                <td>
                  <NumberFormat
                    number={data?.totalFuneralCalInfo.totalRealOfferAmount}
                  />
                  원
                </td>
                {/* <td>
                  <NumberFormat
                    number={data?.totalFuneralCalInfo.totalCancelledOfferAmount}
                  />
                  원
                </td> */}
                <td>
                  <NumberFormat
                    number={data?.totalFuneralCalInfo.totalFuneralAmount}
                  />
                  원
                </td>
                <td>
                  <NumberFormat
                    number={data?.totalFuneralCalInfo.totalDsAmount}
                  />
                  원
                </td>
                <td>
                  <NumberFormat
                    number={data?.totalFuneralCalInfo.totalCnemoAmount}
                  />
                  원
                </td>
              </tr>
            </TableBody>
          </Table>
        ) : (
          <Table className={styles.lowLVTotalCalInfo}>
            <TableBody>
              <TableHeader>
                <th className={styles.exSmallTh}>총 주문 수량</th>
              </TableHeader>

              <tr>
                <td>
                  <NumberFormat
                    number={data?.totalFuneralCalInfo.totalCalCount}
                  />
                </td>
              </tr>
            </TableBody>
          </Table>
        )}

        <AdminCalsTable
          LV={user.LV}
          funeralCalList={data?.funeralCalList}
          totalPages={data?.totalPages || 0}
          location={location}
          updateFuneralCals={updateFuneralCals}
        />
      </main>
    </div>
  );
};

export default AdminCals;
